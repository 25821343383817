// .ct-footer {
//     display: none !important;
//     [data-row="middle"] {
//         .ct-container-fluid {
//             width: 100%;
//             [data-column="widget-area-1"] {
//                 height: 600px;
//                 padding: 0 30px;
//                 background-color: #121A3A;
//             }
//             [data-column="widget-area-2"],
//             [data-column="widget-area-3"],
//             [data-column="widget-area-4"] {
//                 padding: 60px 0;
//             }
//         }
//     }
// }

.entry-content>* {
    margin-bottom: 0 !important;
}

.c-shadow {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
}

.c-shadow2 {
    -webkit-box-shadow: 1px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 1px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 1px 3px 6px 0px rgba(0,0,0,0.16);
    .wp-block-image {
        figure {
            position: relative;
            cursor: pointer;
            img {
                height: 320px;
                object-fit: cover;
            }
            figcaption {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-self: center;
                align-items: center;
                padding: 0 55px;
                font-size: 24px;
                line-height: 36px;
                font-weight: 700;
                text-transform: uppercase;
                color: #FFFFFF;
                opacity: 0;
                transition: opacity .2s ease-out;
                -moz-transition: opacity .2s ease-out;
                -webkit-transition: opacity .2s ease-out;
                -o-transition: opacity .2s ease-out;

            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 138, 203, 0.86);
                border-radius: 10px 10px 0 0;
                opacity: 0;
                transition: opacity .2s ease-out;
                -moz-transition: opacity .2s ease-out;
                -webkit-transition: opacity .2s ease-out;
                -o-transition: opacity .2s ease-out;
            }
            &:hover {
                &:before {
                    opacity: 1;
                }
                figcaption {
                    opacity: 1;
                }
            }
        }
    }
    h4 {
        padding: 0 20px;
        margin-top: 8px !important;
        margin-bottom: 0 !important;
    }
    .t-hover {

    }
    p {
        padding: 0 20px;
        margin-bottom: 15px !important;
    }
    .separ-style {
        width: 50px;
        height: 6px !important;
    }
}

.c-g-shadow {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    .gb-headline {
        .gb-icon {
            svg {
                width: 100%;
            }
        }
    }
}

.p-style {
    line-height: 30px;
}

.p-style2 {
    line-height: 19px !important;
}

.link-style {
    a {
        text-decoration: underline !important;
    }
}

.hero-section {
    h2 {
        margin-top: 30px !important;
    }
}

.b-section {
    h2 {
        margin-top: 30px !important;
    }
}

.l-button {
    .gb-headline {
        position: relative;
        &:after {
            content: '';
            width: 22px;
            height: 17px;
            background: url(/wp-content/uploads/2022/11/arrow_right_alt_FILL0_wght400_GRAD0_opsz48.png);
            position: absolute;
            right: 30px;
        }
    }
}

.title-position {
    position: relative;
    top: -90px;
}

.cover-style {
    align-items: flex-end !important;
    img {
        object-fit: fill !important;
    }
}

.media-style {

}

.stk--inner-svg {
    height: 15px !important;
}

.is-style-outline {
    margin-left: 20px !important;
    a {
        border-color: var(--paletteColor1) !important;
        border-width: 1px !important;
        padding: 5px 14px !important;
    }
}

.b-home {
    a {
        min-height: 40px !important;
        padding: 5px 14px !important;
        border-radius: 10px !important;
        &:hover {
            color: #FFFFFF !important;
            border-color: #f4960d !important;
        }
    }
}

.gb-container-1bf8cb4d {
    .gb-inside-container {
        // display: inline-flex;
        // flex-wrap: wrap;
        // gap: 15px;
        .gb-headline {
            margin: 0 !important;
        }
        .gb-button-wrapper {
            // gap: 15px;
            a.gb-button {
                // color: #FFFFFF;
                .gb-icon {
                    svg {
                        // height: 19px;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.c-bg1 {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        width: 631px;
        height: 631px;
        background: url(/wp-content/uploads/2022/11/Pngtree.png);
        position: absolute;
        right: -165px;
        bottom: -30px;
        display: block;
        background-position: bottom right;
        background-size: auto;
        background-repeat: no-repeat;
    }
}

.c-position {
    position: relative;
    .g-position {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -190px;
        .c-style {
            border-radius: 10px;
            -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
            -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
            box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
        }
    }
}

select, textarea, input[type=url], input[type=tel], input[type=text], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=email], input[type=number], input[type=search], input[type=password], .ct-widget select, fieldset .wc-stripe-elements-field {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.16);
}

h2.gform_title {
    margin-bottom: 50px !important;
}

.gform_wrapper.gravity-theme .gform_fields {
    grid-row-gap: 20px !important;
}

#gform_wrapper_4 {
    .gform_heading {
        h2 {
            margin-bottom: 20px !important;
        }
        .gform_description {
            display: block;
            margin-bottom: 40px;
        }
    }
}

.html-style {
    margin-top: 20px;
    margin-bottom: 10px;
    h4 {
        font-size: 20px;
        line-height: 24px;
        color: #008ACB;
    }
}

.checkboxes-style {
    background-color: #FFFFFF !important;
    padding: 10px !important;
    // .gfield_checkbox {
    //     display: flex;
    //     flex-wrap: wrap;
    //     //justify-content: space-between;
    //     .gchoice {
    //         width: 27%;
    //     }
    // }
}

.list-css {
    padding: 0;
    list-style: none;
    li {
        text-decoration: underline;
        margin-bottom: 10px;
        a {
            color: #333E48;
        }
    }
}

#post-346 {
    position: relative;
    z-index: 9;
}

.bg2-style {
    &:before {
        content: '';
        width: 894px;
        height: 894px;
        background: url(/wp-content/uploads/2022/11/pngegg6.png);
        position: absolute;
        left: -185px;
        bottom: -50px;
        display: block;
        background-position: bottom left;
        background-size: auto;
        background-repeat: no-repeat;
    }
}

.img-css {
    height: 100%;
    .gb-inside-container {
        height: 100%;
        .wp-block-image {
            height: 418px;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }
}

.b-radius-img {
    height: 247px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0 0 5px 5px;
    }
}

.link-pages {
    padding: 0;
    list-style: none;
    li {
        a {
            color: #707070;
            font-weight: 500;
        }
    }
}

li.ct-mega-menu-full-width {
    ul.sub-menu {
        li.menu-item-has-children {
            margin-bottom: 20px;
        }
        li {
            a.ct-menu-link {
                padding: 5px 0;
            }
        }
    }
}

.ct-column-heading {
    margin-top: 30px;
}

.img-width {
    img {
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
}

.list-icon {
    .gb-icon {
        svg {
            width: 15px !important;
        }
    }
}

footer {
    nav {
        ul {
            li {
                a {
                    &:hover {
                        color: #FFFFFF !important;
                    }
                }
            }
        }
    }
    .gb-container-f8910ed8 {
        p {
            line-height: 39px;
            margin-top: -7px;
        }
        a {
            mark {
                font-weight: 300 !important;
            }
        }
    }
    .gb-container-2d7ed596 {
        a {
            mark {
                font-weight: 300 !important;
            }
        }
    }
}

#gform_wrapper_2 {
    position: relative;
    form {
        .gform_body {
            #field_2_7 {
                input {
                    height: 40px !important;
                    border-radius: 5px !important;
                }
            }
            #field_2_9 {
                label {
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
        .gform_footer {
            position: absolute;
            top: 0;
            right: 60px;
            margin: 0 !important;
            padding: 0 !important;
            input.gform_button {
                min-height: 40px !important;
                padding: 5px 14px !important;
                border-radius: 5px !important;
            }
        }
    }
}

#field_4_6 {
    display: inherit;
    label {
        order: 2;
        margin-bottom: 0 !important;
    }
    .ginput_container {
        order: 1;
        margin-bottom: 8px !important;
    }
}

label.gfield_label {
    font-size: 14px !important;
    font-weight: 300 !important;
}
legend.gfield_label {
    font-size: 16px !important;
    font-weight: 400 !important;
}
.checkboxes-style {
    legend {
        height: 0 !important;
        padding-top: 23px !important;
        margin-bottom: 20px !important;
    }
}

#gform_wrapper_4 {
    input.gfield-choice-input {
        margin-right: 7px !important;
    }
}


@include break-below(lg) {
    article>.entry-content>*.alignfull {
        .gb-inside-container {
            width: var(--default-editor, var(--block-width));
        }
        //width: var(--default-editor, var(--block-width));
    }
}

@include break-below(md) {
    .c-position {
        .g-position {
            position: initial;
        }
    }
}

@include break-below(sm) {
    .gb-grid-column-48753d7c {
        display: none;
    }
}

@include break-below(xs) {
    .p-style {
        font-size: 16px !important;
        line-height: 26px !important;
    }
    .buttons-home {
        display: block !important;
        .b-home {
            margin-top: 20px;
            margin-left: 0 !important;
        }
    }
}